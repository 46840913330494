<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        {{$t('title.editar_perfil')}}
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="grupo.name"
              :disabled="somenteLeitura"
              :label="`${$tc('label.nome', 1)} *`"
              :rules="[rules.required]"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="grupo.descricao"
              :disabled="somenteLeitura"
              :label="$tc('label.descricao', 1)">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="mt-1">
            <v-switch
              v-model="grupo.indPerfilUsuarioExterno"
              :label="$t('label.perfil_usuario_externo')"
              color="primary"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="searchRole"
              append-icon="filter_list"
              :label="$t('label.filtrar')"
              single-line
              clearable
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" offset-md="2">
            <v-switch
              v-model="somenteSelecionados"
              :label="$t('label.exibir_somente_selecionados')"
              color="primary"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <v-list two-line subheader style="height: 430px; overflow-y: auto;" v-if="somenteSelecionados">
              <role-item-lista
                v-for="(role, rowIndex) in rolesFiltradasSelecionadas"
                :key="`${rowIndex}-${role}`"
                :somente-leitura="somenteLeitura"
                :role="role"
                :roleIndex="rowIndex"
                :selecionado="roleSelecionada(role)"
                @ROLEITEMLISTA_selecionar="selecionar(role)">
              </role-item-lista>
            </v-list>
            <v-list two-line subheader style="height: 430px; overflow-y: auto;" v-else>
              <role-item-lista
                v-for="(role, rowIndex) in rolesFiltradas"
                :key="`${rowIndex}-${role}-selecionado`"
                :somente-leitura="somenteLeitura"
                :role="role"
                :roleIndex="rowIndex"
                :selecionado="roleSelecionada(role)"
                @ROLEITEMLISTA_selecionar="selecionar(role)">
              </role-item-lista>
            </v-list>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="canAccessCRUD"
          @click.native="save"
          color="primary">{{ $t('label.salvar') }}
        </v-btn>
      </v-card-actions>

      <confirm ref="confirmarSave"
        :persistent="true"
        :message="$t('message.deseja_salvar', {text: $tc('label.perfil', 1)})"
        :title="$t('label.atencao')"
        :agree-label="$t('label.sim')"
        :disagree-label="$t('label.nao')"
        @agree="salvarGrupo()"
        />

      <confirm ref="dialogConfirmaCancelamento"
        :persistent="true"
        :message="$t('message.tem_certeza_cancelar')"
        :title="$t('label.atencao')"
        :agree-label="$t('label.sim')"
        :disagree-label="$t('label.nao')"
        @agree="rotearParaLista()"
        />
    </v-card>
  </v-form>
</template>

<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { showLoading, hideLoading } from '../../../common/functions/loading';
import CRUDGenerico from '../../../shared-components/generico/CRUDGenerico';
import RoleItemLista from './RoleItemLista';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'GrupoForm',
  extends: CRUDGenerico,
  props: {
    somenteLeitura: true,
  },
  data() {
    return {
      ssoGrupo: this.$api.ssoGrupo(this.$resource),
      ssoRole: this.$api.ssoRole(this.$resource),
      roles: [],
      rolesFiltradas: [],
      rolesSelecionadas: [],
      somenteSelecionados: false,
      formValido: true,
      searchRole: null,
      grupo: {
        name: '',
        descricao: '',
      },
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    RoleItemLista,
    Confirm,
  },
  watch: {
    searchRole() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    ...generateComputed('Perfil', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    temRoleSelecionada() {
      return !!this.rolesSelecionadas.length;
    },
    isNovoCadastro() {
      return !this.$route.params.id;
    },
  },
  methods: {
    selecionar(role) {
      if (this.roleSelecionada(role)) {
        this.rolesSelecionadas.splice(this.rolesSelecionadas.indexOf(role), 1);
      } else {
        this.rolesSelecionadas.push(role);
      }
    },
    filtrar() {
      showLoading();

      setTimeout(() => {
        this.rolesFiltradas = this.roles.filter((r) => !this.searchRole
          || this.$t(`roles.${r}.titulo`).toLowerCase().includes(this.searchRole.toLowerCase()));
        this.rolesFiltradasSelecionadas = this.rolesSelecionadas.filter((r) => !this.searchRole
          || this.$t(`roles.${r}.titulo`).toLowerCase().includes(this.searchRole.toLowerCase()));

        setTimeout(() => hideLoading(), 500);
      });
    },
    roleSelecionada(role) {
      return this.rolesSelecionadas.includes(role);
    },
    rotearParaLista() {
      this.$router.push({ name: 'sso-grupo' });
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      if (cb) {
        cb();
      }
    },
    save() {
      this.formValido = this.temRoleSelecionada;
      if (!this.$refs.form.validate() || !this.formValido) {
        return;
      }
      this.$refs.confirmarSave.open();
    },
    cancel() {
      this.$refs.dialogConfirmaCancelamento.open();
    },
    salvarGrupo() {
      const grupo = { ...this.grupo };
      grupo.roles = this.rolesSelecionadas;

      if (this.isNovoCadastro) {
        this.adicionar(grupo);
      } else {
        this.atualizar(grupo);
      }
    },
    adicionar(grupo) {
      this.ssoGrupo.adicionar(grupo)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.rotearParaLista();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    atualizar(grupo) {
      this.ssoGrupo.salvar(grupo)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.rotearParaLista();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    accessForbidden() {
      this.$router.push({ name: 'Page403' });
    },
    carregarRoles() {
      this.ssoRole.listar()
        .then((res) => {
          this.roles = res.data;
          setTimeout(() => this.filtrar());
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarGrupo(id) {
      return this.ssoGrupo.obter({ id })
        .then((res) => {
          this.grupo = res.data;
          this.rolesSelecionadas = this.grupo.roles;
          this.grupo.indPerfilUsuarioExterno = (String(this.grupo.indPerfilUsuarioExterno).toLowerCase() === 'true');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
      return;
    }

    if (!this.isNovoCadastro) {
      this.carregarGrupo(this.$route.params.id)
        .then(() => this.carregarRoles());
      return;
    }

    this.carregarRoles();
  },
};
</script>
