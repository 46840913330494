<template>
  <v-list-item @click.native="selecionar(role)" :disabled="somenteLeitura">
    <v-list-item-content>
      <v-list-item-title>{{ $t(`roles.${role}.titulo`) }}</v-list-item-title>
      <v-list-item-subtitle>{{ $t(`roles.${role}.descricao`) }}</v-list-item-subtitle>
      <v-tooltip left><span>{{$t('label.nova_perfil')}}</span></v-tooltip>
    </v-list-item-content>
    <v-list-item-action style="justify-content: center;">
      <v-switch
        v-model="valor"
        color="primary"
        :id="`grupo-role-selecionada-${roleIndex}`"
        :name="`grupo-role-selecionada-${roleIndex}`"/>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'RoleItemLista',
  data() {
    return {
      valor: false,
    };
  },
  props: {
    somenteLeitura: true,
    roleIndex: {
      default: 0,
      type: Number,
    },
    role: {
      default: '',
      type: String,
    },
    selecionado: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    selecionado(val) {
      this.valor = val;
    },
    role() {
      this.valor = this.selecionado;
    },
  },
  methods: {
    selecionar(role) {
      this.$emit('ROLEITEMLISTA_selecionar', role);
    },
  },
  mounted() {
    this.valor = this.selecionado;
  },
};
</script>
